<!-- eslint-disable max-len -->
<template>
  <a target="_blank" href="https://www.panoscope.ca">
    <svg :class="props.class" viewBox="0 0 75 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 1.28223H2.74865C4.84278 1.28223 5.36639 2.35108 5.36639 3.92171V7.412C5.36639 8.98263 4.79921 10.008 2.70508 10.008H1.70162V16.5523H0V1.28223ZM2.68315 8.43738C3.44663 8.43738 3.66476 8.0666 3.66476 7.4121V3.9218C3.66476 3.26739 3.44663 2.91835 2.68315 2.91835H1.70143V8.43738H2.68315Z"
        fill="#4E4E4E"
      />
      <path
        d="M12.3483 16.5523H10.6249L10.2759 13.2365H8.31254L7.9635 16.5523H6.24023L8.2253 1.28223H10.3631L12.3483 16.5523ZM8.46527 11.6659H10.1014L9.29425 3.7909L8.46527 11.6659Z"
        fill="#4E4E4E"
      />
      <path
        d="M18.7599 12.2985C18.6509 10.1826 18.5855 8.39371 18.5855 6.27774V1.28223H20.2216V16.5523H18.5855L15.6623 5.536C15.7496 7.6083 15.8368 9.37533 15.8368 11.5568V16.5522H14.2007V1.28223H15.8368L18.7599 12.2985Z"
        fill="#4E4E4E"
      />
      <path
        d="M44.2635 12.2331C44.2635 7.89203 40.6423 9.63723 40.6423 5.09972V3.74723C40.6423 2.17661 41.2095 1.06409 43.3037 1.06409C45.398 1.06409 45.9651 2.17661 45.9651 3.74723V5.42693H44.2636V3.74723C44.2636 3.09282 44.0672 2.59105 43.3038 2.59105C42.5405 2.59105 42.3441 3.09282 42.3441 3.74723V5.03432C42.3441 8.72092 45.9652 6.99765 45.9652 12.1677V14.0874C45.9652 15.658 45.3981 16.7705 43.3038 16.7705C41.2096 16.7705 40.6424 15.658 40.6424 14.0874V12.1896H42.344V14.0874C42.344 14.7418 42.5403 15.2436 43.3037 15.2436C44.0672 15.2436 44.2635 14.7418 44.2635 14.0874V12.2332V12.2331Z"
        fill="#4E4E4E"
      />
      <path
        d="M51.7004 12.1895H53.3583V14.0873C53.3583 15.6579 52.682 16.7705 50.7188 16.7705C48.6247 16.7705 48.0356 15.6579 48.0356 14.0873V3.74723C48.0356 2.17661 48.6247 1.06409 50.7188 1.06409C52.682 1.06409 53.3583 2.17661 53.3583 3.74723V5.42693H51.7004V3.74723C51.7004 3.09282 51.4822 2.59105 50.7188 2.59105C49.9554 2.59105 49.7371 3.09282 49.7371 3.74723V14.0873C49.7371 14.7417 49.9553 15.2435 50.7188 15.2435C51.4823 15.2435 51.7004 14.7417 51.7004 14.0873V12.1895Z"
        fill="#4E4E4E"
      />
      <path
        d="M55.3186 14.0873V3.74723C55.3186 2.17661 55.9076 1.06409 58.0017 1.06409C60.0959 1.06409 60.6848 2.17661 60.6848 3.74723V14.0873C60.6848 15.6579 60.0959 16.7705 58.0017 16.7705C55.9076 16.7705 55.3186 15.6579 55.3186 14.0873ZM57.02 14.0873C57.02 14.7417 57.2383 15.2435 58.0017 15.2435C58.7652 15.2435 58.9834 14.7417 58.9834 14.0873V3.74723C58.9834 3.09282 58.7651 2.59105 58.0017 2.59105C57.2384 2.59105 57.02 3.09282 57.02 3.74723V14.0873Z"
        fill="#4E4E4E"
      />
      <path
        d="M63.0837 1.28223H65.8324C67.9265 1.28223 68.4501 2.35108 68.4501 3.92171V7.412C68.4501 8.98263 67.8829 10.008 65.7887 10.008H64.7853V16.5523H63.0837V1.28223ZM65.7669 8.43738C66.5304 8.43738 66.7485 8.0666 66.7485 7.4121V3.9218C66.7485 3.26739 66.5304 2.91835 65.7669 2.91835H64.7853V8.43738H65.7669Z"
        fill="#4E4E4E"
      />
      <path
        d="M72.2289 14.9163H74.9994V16.5524H70.5273V1.28223H74.9994V2.91835H72.2289V8.04467H74.6285V9.6154H72.2289V14.9163Z"
        fill="#4E4E4E"
      />
      <path
        d="M39.1788 9.07394H35.9324C35.8527 9.07394 35.7881 9.0094 35.7881 8.92967C35.7881 8.84994 35.8527 8.7854 35.9324 8.7854H39.1788C39.2585 8.7854 39.3231 8.84994 39.3231 8.92967C39.3231 9.0094 39.2585 9.07394 39.1788 9.07394Z"
        fill="#4E4E4E"
      />
      <path
        d="M35.737 7.53113C35.6733 7.53113 35.6149 7.48862 35.5977 7.42418C35.5771 7.34723 35.6228 7.26808 35.6998 7.24749L38.7597 6.42755C38.837 6.40697 38.9159 6.45247 38.9365 6.5296C38.9571 6.60655 38.9114 6.6857 38.8345 6.70629L35.7745 7.52622C35.7619 7.52959 35.7494 7.53113 35.737 7.53113Z"
        fill="#4E4E4E"
      />
      <path
        d="M35.142 6.0933C35.0921 6.0933 35.0437 6.06743 35.0169 6.02117C34.9771 5.9522 35.0007 5.86391 35.0697 5.82409L37.6846 4.31444C37.7535 4.27453 37.8418 4.29819 37.8817 4.36725C37.9215 4.43621 37.8979 4.5245 37.8288 4.56432L35.214 6.07397C35.1913 6.08715 35.1665 6.0933 35.142 6.0933Z"
        fill="#4E4E4E"
      />
      <path
        d="M34.1905 4.85893C34.1535 4.85893 34.1166 4.84489 34.0884 4.81671C34.0321 4.76044 34.0321 4.66907 34.0884 4.61271L36.0579 2.64322C36.1143 2.58686 36.2055 2.58686 36.2619 2.64322C36.3183 2.69949 36.3183 2.79086 36.2619 2.84722L34.2924 4.81671C34.2642 4.84489 34.2274 4.85893 34.1905 4.85893Z"
        fill="#4E4E4E"
      />
      <path
        d="M32.9595 3.95882C32.9269 3.95882 32.8938 3.95055 32.8635 3.93294C32.7715 3.87985 32.74 3.76222 32.7931 3.67018L34.0776 1.44533C34.1308 1.35329 34.2481 1.32155 34.3404 1.37493C34.4324 1.42802 34.4639 1.54565 34.4108 1.63769L33.1263 3.86254C33.0906 3.92429 33.026 3.95882 32.9595 3.95882Z"
        fill="#4E4E4E"
      />
      <path
        d="M31.521 3.41024C31.5003 3.41024 31.4793 3.40764 31.4586 3.40206C31.3303 3.36773 31.2542 3.23577 31.2885 3.10756L31.8971 0.835777C31.9316 0.707568 32.0638 0.631682 32.1916 0.66573C32.3199 0.700066 32.396 0.832026 32.3617 0.960234L31.7531 3.23202C31.7242 3.33945 31.6271 3.41024 31.521 3.41024Z"
        fill="#4E4E4E"
      />
      <path
        d="M29.9782 0.547852C30.1375 0.547852 30.2668 0.677118 30.2668 0.836393V2.96746C30.2668 3.12674 30.1375 3.256 29.9782 3.256H29.978C29.8189 3.256 29.6897 3.12683 29.6897 2.96775V0.836393C29.6897 0.677118 29.819 0.547852 29.9782 0.547852Z"
        fill="#4E4E4E"
      />
      <path
        d="M28.4351 3.50551C28.2865 3.50551 28.1505 3.40635 28.1102 3.25592L27.6126 1.39906C27.5645 1.21949 27.6711 1.03492 27.8507 0.986736C28.03 0.938742 28.2148 1.04512 28.263 1.22478L28.7605 3.08164C28.8086 3.26121 28.7021 3.44578 28.5225 3.49397C28.4933 3.50176 28.4639 3.50551 28.4351 3.50551Z"
        fill="#4E4E4E"
      />
      <path
        d="M26.9955 4.14942C26.8624 4.14942 26.7332 4.08046 26.6619 3.95706L25.7847 2.43779C25.6786 2.2538 25.7416 2.01854 25.9255 1.91226C26.1096 1.80589 26.3449 1.86908 26.4511 2.05307L27.3282 3.57234C27.4344 3.75633 27.3714 3.99159 27.1874 4.09787C27.1268 4.13288 27.0607 4.14942 26.9955 4.14942Z"
        fill="#4E4E4E"
      />
      <path
        d="M25.7591 5.14575C25.6483 5.14575 25.5376 5.10353 25.4531 5.01899L24.2942 3.86001C24.1252 3.69093 24.1252 3.41691 24.2942 3.24792C24.4633 3.07893 24.7373 3.07903 24.9063 3.24792L26.0652 4.4069C26.2342 4.57598 26.2342 4.85 26.0652 5.01899C25.9806 5.10343 25.8698 5.14575 25.7591 5.14575Z"
        fill="#4E4E4E"
      />
      <path
        d="M24.8128 6.42821C24.7312 6.42821 24.6485 6.40743 24.5728 6.36367L23.211 5.57749C22.9809 5.44476 22.9022 5.15064 23.035 4.92058C23.1677 4.69061 23.4618 4.61174 23.6919 4.74457L25.0537 5.53075C25.2838 5.66348 25.3626 5.9576 25.2297 6.18766C25.1407 6.34193 24.979 6.42821 24.8128 6.42821Z"
        fill="#4E4E4E"
      />
      <path
        d="M24.2176 7.91479C24.1723 7.91479 24.1263 7.90892 24.0804 7.89661L22.5909 7.49756C22.3087 7.42196 22.1413 7.13188 22.2169 6.84969C22.2925 6.5674 22.5828 6.40014 22.8648 6.47564L24.3542 6.8747C24.6364 6.95029 24.8038 7.24037 24.7283 7.52257C24.665 7.75898 24.4512 7.91479 24.2176 7.91479Z"
        fill="#4E4E4E"
      />
      <path
        d="M24.0162 9.45874H22.4819C22.1897 9.45874 21.9529 9.22185 21.9529 8.92975C21.9529 8.63765 22.1897 8.40076 22.4819 8.40076H24.0162C24.3084 8.40076 24.5452 8.63755 24.5452 8.92975C24.5452 9.22195 24.3084 9.45874 24.0162 9.45874Z"
        fill="#4E4E4E"
      />
      <path
        d="M38.7974 11.4368C38.785 11.4368 38.7725 11.4352 38.76 11.4319L35.6998 10.6121C35.6228 10.5914 35.5772 10.5124 35.5977 10.4354C35.6184 10.3584 35.6974 10.3132 35.7745 10.3333L38.8346 11.1531C38.9116 11.1738 38.9572 11.2528 38.9367 11.3299C38.9194 11.3943 38.8611 11.4368 38.7974 11.4368Z"
        fill="#4E4E4E"
      />
      <path
        d="M37.7535 13.5645C37.729 13.5645 37.7042 13.5583 37.6815 13.5451L35.0665 12.0355C34.9975 11.9957 34.9739 11.9075 35.0137 11.8384C35.0536 11.7693 35.142 11.7458 35.2108 11.7856L37.8258 13.2953C37.8948 13.3351 37.9184 13.4233 37.8786 13.4923C37.8519 13.5386 37.8034 13.5645 37.7535 13.5645Z"
        fill="#4E4E4E"
      />
      <path
        d="M36.1599 15.2586C36.123 15.2586 36.0861 15.2445 36.0579 15.2163L34.0884 13.2468C34.0321 13.1904 34.0321 13.0991 34.0884 13.0428C34.1448 12.9864 34.2361 12.9864 34.2924 13.0428L36.2619 15.0123C36.3183 15.0687 36.3183 15.16 36.2619 15.2163C36.2337 15.2445 36.1968 15.2586 36.1599 15.2586H36.1599Z"
        fill="#4E4E4E"
      />
      <path
        d="M34.2443 16.5104C34.1778 16.5104 34.1132 16.476 34.0775 16.4143L32.7931 14.1895C32.74 14.0975 32.7715 13.9798 32.8635 13.9267C32.9554 13.8735 33.0732 13.9052 33.1263 13.9971L34.4107 16.2219C34.4638 16.3139 34.4323 16.4316 34.3403 16.4847C34.3101 16.5022 34.2769 16.5104 34.2443 16.5104Z"
        fill="#4E4E4E"
      />
      <path
        d="M32.1292 17.2021C32.023 17.2021 31.926 17.1312 31.8971 17.0237L31.2885 14.752C31.2542 14.6237 31.3303 14.4919 31.4587 14.4575C31.586 14.4235 31.7186 14.4992 31.7532 14.6276L32.3618 16.8994C32.3961 17.0276 32.3199 17.1594 32.1916 17.1939C32.1709 17.1995 32.1499 17.2022 32.1293 17.2022L32.1292 17.2021Z"
        fill="#4E4E4E"
      />
      <path
        d="M29.9782 17.3115C29.8189 17.3115 29.6897 17.1824 29.6897 17.023V14.8919C29.6897 14.7326 29.8189 14.6034 29.9782 14.6034C30.1376 14.6034 30.2668 14.7326 30.2668 14.8919V17.023C30.2668 17.1824 30.1376 17.3115 29.9782 17.3115Z"
        fill="#4E4E4E"
      />
      <path
        d="M27.938 16.8842C27.9091 16.8842 27.8799 16.8805 27.8507 16.8727C27.6711 16.8246 27.5645 16.6399 27.6126 16.4604L28.1102 14.6033C28.1582 14.4237 28.343 14.3173 28.5225 14.3653C28.7021 14.4134 28.8086 14.598 28.7605 14.7776L28.263 16.6346C28.2227 16.785 28.0867 16.8842 27.938 16.8842Z"
        fill="#4E4E4E"
      />
      <path
        d="M26.1176 15.9989C26.0523 15.9989 25.9862 15.9823 25.9256 15.9473C25.7416 15.8411 25.6785 15.6057 25.7848 15.4217L26.6619 13.9025C26.7681 13.7185 27.0034 13.6552 27.1875 13.7617C27.3715 13.8679 27.4346 14.1032 27.3283 14.2872L26.4511 15.8065C26.3798 15.9299 26.2506 15.9989 26.1176 15.9989Z"
        fill="#4E4E4E"
      />
      <path
        d="M24.6003 14.7384C24.4895 14.7384 24.3788 14.6962 24.2942 14.6117C24.1252 14.4427 24.1252 14.1686 24.2942 13.9997L25.4531 12.8407C25.6222 12.6716 25.8961 12.6716 26.0652 12.8407C26.2342 13.0097 26.2342 13.2837 26.0652 13.4527L24.9063 14.6117C24.8218 14.6962 24.7111 14.7384 24.6003 14.7384Z"
        fill="#4E4E4E"
      />
      <path
        d="M23.4519 13.1794C23.2856 13.1794 23.1241 13.0932 23.035 12.9388C22.9022 12.7088 22.9809 12.4147 23.211 12.2819L24.5729 11.4956C24.8032 11.3626 25.0971 11.4417 25.2298 11.6716C25.3627 11.9017 25.2839 12.1957 25.0538 12.3286L23.6919 13.1148C23.6161 13.1586 23.5335 13.1794 23.4519 13.1794Z"
        fill="#4E4E4E"
      />
      <path
        d="M22.7275 11.4019C22.4939 11.4019 22.2802 11.2461 22.2169 11.0098C22.1413 10.7275 22.3087 10.4375 22.5909 10.3619L24.08 9.96295C24.3621 9.88707 24.6523 10.0548 24.7279 10.337C24.8035 10.6193 24.636 10.9093 24.3538 10.9849L22.8648 11.3838C22.8189 11.3961 22.7728 11.4019 22.7275 11.4019Z"
        fill="#4E4E4E"
      />
    </svg>
  </a>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-20'
  }
)
</script>

<style scoped></style>
