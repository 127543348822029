<!-- eslint-disable max-len -->
<template>
  <a target="_blank" href="https://ouvoir.ca/">
    <svg :class="props.class" viewBox="0 0 66 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8346 1.82248C15.0052 -0.609413 8.45623 -0.607429 2.6283 1.82806C0.215745 7.70417 0.217713 14.3057 2.63384 20.1803C8.46323 22.6121 15.0122 22.6101 20.8401 20.1747C23.2527 14.2986 23.2507 7.69712 20.8346 1.82248ZM15.4811 3.90431L18.7723 5.81835L18.0297 7.1158L14.7385 5.20176L15.4811 3.90431ZM6.70674 15.3641C4.44607 15.3647 2.61292 13.518 2.61219 11.2393C2.61153 8.96049 4.4436 7.11264 6.70419 7.1119C8.96486 7.11124 10.798 8.95799 10.7987 11.2367C10.7995 13.5155 8.96734 15.3633 6.70674 15.3641ZM11.7364 18.2406L9.87907 15.5905L10.9579 14.8222L11.7358 15.932L12.513 14.8217L13.5922 15.5893L11.7364 18.2406ZM16.7643 15.3786C14.5037 15.3794 12.6705 13.5326 12.6698 11.2538L12.6687 7.69359L14.1731 7.69315L14.1742 11.2534C14.1746 12.7084 15.3625 13.8888 16.8119 13.8618C18.2096 13.8357 19.3524 12.6388 19.352 11.2297L19.3509 7.69161L20.8553 7.69117L20.8563 11.2514C20.8571 13.5302 19.025 15.378 16.7643 15.3788V15.3786Z"
        fill="#4E4E4E"
      />
      <path
        d="M9.29197 11.3477C9.352 9.90769 8.24258 8.69128 6.81402 8.63077C5.38546 8.57027 4.17872 9.68858 4.11869 11.1286C4.05867 12.5686 5.16809 13.785 6.59665 13.8455C8.02521 13.906 9.23195 12.7877 9.29197 11.3477Z"
        fill="#4E4E4E"
      />
      <path d="M42.685 6.70642H41.1902V14.7616H42.685V6.70642Z" fill="#4E4E4E" />
      <path
        d="M45.772 7.86702L45.7716 6.70276L44.2769 6.7032L44.2793 14.779L45.7741 14.7785L45.773 11.0724C45.7726 9.6046 46.9569 8.41007 48.413 8.40963L48.4126 6.90288C47.4094 6.90317 46.4887 7.26564 45.772 7.86702Z"
        fill="#4E4E4E"
      />
      <path
        d="M30.8892 6.70435L27.9267 11.9896L24.9607 6.70633L23.6597 7.44847L27.0685 13.5204L27.0826 13.5456L27.9276 15.0506L28.7716 13.545L28.7858 13.5198L32.1907 7.44567L30.8892 6.70435Z"
        fill="#4E4E4E"
      />
      <path
        d="M36.0445 8.087C37.4686 8.08656 38.6275 9.25405 38.628 10.6896C38.6284 12.1251 37.4702 13.2933 36.046 13.2938C34.622 13.2942 33.463 12.1267 33.4626 10.6912C33.4622 9.25567 34.6204 8.08744 36.0445 8.087ZM36.0441 6.57422C33.7889 6.57488 31.9612 8.41832 31.9619 10.6917C31.9626 12.965 33.7914 14.8073 36.0466 14.8066C38.3018 14.8059 40.1295 12.9625 40.1288 10.6891C40.1281 8.41582 38.2994 6.57348 36.0441 6.57422Z"
        fill="#4E4E4E"
      />
      <path
        d="M65.9901 10.6691C65.9829 8.40137 64.1569 6.56543 61.9057 6.56616C59.6504 6.56682 57.8228 8.41026 57.8235 10.6836C57.8241 12.9569 59.6529 14.7992 61.9082 14.7985C62.8914 14.7982 63.7931 14.4475 64.4976 13.8639L64.4979 14.7235L65.9993 14.723L65.9981 10.6691H65.9901ZM61.9077 13.2857C60.4836 13.2862 59.3247 12.1187 59.3242 10.6831C59.3238 9.24761 60.482 8.07938 61.9062 8.07894C63.3302 8.0785 64.4892 9.24599 64.4896 10.6816C64.4901 12.117 63.3318 13.2853 61.9077 13.2857Z"
        fill="#4E4E4E"
      />
      <path
        d="M56.3287 12.4545C55.322 13.4698 53.6835 13.4703 52.6762 12.4556C51.669 11.4408 51.6685 9.7892 52.6751 8.77385C53.6818 7.7585 55.3203 7.75799 56.3276 8.77275L57.3884 7.70274C55.7932 6.09578 53.2078 6.09659 51.6136 7.70451C50.0194 9.31249 50.0202 11.9187 51.6153 13.5257C52.2225 14.1373 52.9732 14.5154 53.7581 14.6612L53.7584 15.5046L55.2531 15.5041L55.2528 14.66C56.0359 14.513 56.7846 14.1347 57.3901 13.5239L56.3286 12.4546L56.3287 12.4545Z"
        fill="#4E4E4E"
      />
      <path
        d="M49.3901 12.6458C48.7672 12.3859 48.0673 12.3861 47.4445 12.6464C47.1866 13.2744 47.1868 13.9799 47.445 14.6077C48.068 14.8676 48.7679 14.8674 49.3907 14.6071C49.6486 13.9791 49.6484 13.2736 49.3901 12.6458Z"
        fill="#4E4E4E"
      />
    </svg>
  </a>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-20'
  }
)
</script>

<style scoped></style>
